import Image from 'next/image'
import { useEffect, useState } from 'react'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'
import { useQuery } from '@apollo/client'

import { getClientLogos } from '../graphql/getClientLogos'

import styles from '../styles/ClientTicker.module.scss'


export default function ClientTicker() {

    const { loading: clientsLoading, error: clientsError, data: clientsData } = useQuery(getClientLogos);

    useEffect(() => {
        if (clientsData) {
            const doc = document.documentElement
            doc.style.setProperty('--client-ticker-width', `${clientsData?.clientLogo?.data?.attributes?.Items?.data?.length * (window.innerWidth < 768 ? 165 : 215)}px`)
        }
    }, [clientsData])

    const [pageIsVisible, setPageIsVisible] = useState(true)

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }

    return (
        <div className={styles.ticker}>
            <PageVisibility onChange={handleVisibilityChange}>
                {pageIsVisible && clientsData && (
                    <Ticker height={window.innerWidth < 768 ? 125 : 200} speed={5}>
                        {() => (
                            clientsData?.clientLogo?.data?.attributes?.Items?.data?.map(client => (
                                <span className={styles.logo}>
                                    {/* <Image src={`/img/clients/${client.file} `} alt={client.name} width={window.innerWidth < 768 ? 100 : 150} height={window.innerWidth < 768 ? 100 : 150} /> */}
                                    <Image src={`${process.env.NEXT_PUBLIC_API_URL}${client.attributes.url}`} alt={client.attributes.alternativeText} width={window.innerWidth < 768 ? 125 : 175} height={window.innerWidth < 768 ? 125 : 175} />
                                </span>
                            ))
                        )}
                    </Ticker>
                )}
            </PageVisibility>
        </div >
    )
}