import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'

import styles from '../styles/TreeCounter.module.scss'

export default function TreeCounter() {

    const [treeCount, setTreeCount] = useState(0)

    useEffect(async () => {
        const res = await fetch('https://api.treemates.net/auth/stats/?slug=treemates_platform')
        const data = await res.json();
        setTreeCount(data.tree_count)
    }, [])

    return (
        <div className={styles.wrapper}>
            <Link href="/projekte/treemates" prefetch={false}>
                <a>
                    <span className={styles.count}>{treeCount} Bäume gepflanzt <Image alt="Baum mit Smiley" src="/img/tree.svg" width="40" height="50" /></span>
                </a>
            </Link>
        </div>
    )
}