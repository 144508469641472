import Head from 'next/head'
import Link from 'next/link'
import styles from '../styles/Home.module.scss'
import { initializeApollo } from '../lib/apolloClient'
import Layout from '../components/layout'
import { Pagination, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ClientTicker from '../components/ClientTicker'
import Image from 'next/image'
import useNextBlurhash from 'use-next-blurhash'
import { useRouter } from 'next/router'

import { useQuery } from '@apollo/client'
import { getJobs } from '../graphql/getJobs'

import 'swiper/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownLong } from '@fortawesome/pro-regular-svg-icons'

import { getSpotlightProjects } from '../graphql/getSpotlightProjects'
import Spacer from '../components/Spacer'
import Reveal from '../components/Reveal'
import JobItem from '../components/content/JobItem'
import TreeCounter from '../components/TreeCounter'


function Home({ projects }) {

  const { locale } = useRouter()
  const { loading: jobsLoading, error: jobsError, data: jobsData } = useQuery(getJobs, { variables: { locale: locale } });

  return (
    <>
      <Head>
        <title>TRONIC | E-Commerce, Apps, Individuelle Softwareentwicklung</title>
        <meta name="description" content="Wir sind Ihr Partner für Digitalisierung (Apps, Frontends, Backends), E-Commerce (Programmierung von Online-Shop) und die New Economy (neue digitale Geschäftsmodelle)." />
        <meta property="og:title" content="TRONIC | E-Commerce, Apps, Individuelle Softwareentwicklung" />
        <meta property="og:description" content="Wir sind Ihr Partner für Digitalisierung (Apps, Frontends, Backends), E-Commerce (Programmierung von Online-Shop) und die New Economy (neue digitale Geschäftsmodelle)." />
        <meta property="og:image" content="https://tronic.digital/img/oggraph.jpg" />
      </Head>
      <Layout page="home">
        <div className={styles.hero}>
          <Reveal>
            <h1>
              Wir sind Ihr Partner für <span className="underline">Digitalisierung</span>, <span className="underline">E-Commerce</span> und die <span className="underline">New Economy</span>.
            </h1>
          </Reveal>
          <Reveal>
            <p>
              <Link prefetch={false} href="/ueber-uns">
                <a className="cta">
                  Über uns
                </a>
              </Link>
            </p>
          </Reveal>
        </div>
        <Reveal>
          <h2 id="intro" className='small'>Spotlight</h2>
        </Reveal>
        <div className="fullWidth">
          <Swiper
            slidesPerView={1.25}
            cssMode={true}
            spaceBetween={20}
            modules={[Pagination, Navigation]}
            navigation
            pagination={{ clickable: true }}
            loop={false}
            className="projectSlider"
            breakpoints={{
              768: {
                slidesPerView: 2.25,
              }
            }}
          >

            {projects.map(project => (
              <SwiperSlide className={styles.slide} key={`project-${project.attributes.Slug}`}>
                <Image className={styles.heroDesktop} alt={project.attributes.Title} priority layout="fill" src={`${process.env.NEXT_PUBLIC_API_URL}${project?.attributes?.Splash?.data?.attributes?.url}`} alt={project.attributes.Title} placeholder="blur" blurDataURL={useNextBlurhash(project.attributes.BlurHash)} />
                <Image className={styles.heroMobile} alt={project.attributes.Title} priority layout="fill" src={`${process.env.NEXT_PUBLIC_API_URL}${project?.attributes?.SplashMobile?.data?.attributes?.url}`} alt={project.attributes.Title} placeholder="blur" blurDataURL={useNextBlurhash(project.attributes.BlurHash)} />
                <div className={styles.projectMeta}>
                  <Reveal>
                    <h3>{project.attributes.Title}</h3>
                    <div className={styles.subtitle}>{project.attributes.Subtitle}</div>
                    <Link prefetch={false} href={`/projekte/${project.attributes.Slug}`}>
                      <a className={styles.projectCta}>
                        Mehr erfahren
                      </a>
                    </Link>
                  </Reveal>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Spacer height={30} />
        <Reveal>
          <TreeCounter />
        </Reveal>
        <Spacer height={30} />
        <div className="fullWidth">
          <ClientTicker />
        </div>
        <Spacer height={30} />
        <Reveal>
          <h2 className='small'>Wir stellen ein</h2>
        </Reveal>
        {jobsData?.jobs?.data?.length > 0 ? jobsData?.jobs?.data?.map((job, i) => <JobItem index={i} job={job?.attributes} />) : <p>Derzeit keine offenen Stellen…</p>}
        <Reveal>
          <p>
            <Link prefetch={false} href="/karriere">
              <a className="cta">
                Karriere
              </a>
            </Link>
          </p>
        </Reveal>
        <Spacer height={75} />
      </Layout >
    </>
  )
}

export default Home

export async function getServerSideProps(context) {
  const apolloClient = initializeApollo();

  const apiResponse = await apolloClient.query({
    query: getSpotlightProjects
  })

  const getShuffledArr = arr => {
    const newArr = arr.slice()
    for (let i = newArr.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr
  };

  return {
    props: {
      projects: getShuffledArr(apiResponse.data.projects.data)
    },
  }
}