import { gql } from "@apollo/client";

export const getJobs = gql`
    query getJobs($locale: I18NLocaleCode) {
        jobs(locale: $locale) {
            data {
                attributes {
                    Title
                    Time
                    Location
                    Slug
                }
            }
        }
    }
`;