import { gql } from "@apollo/client";

export const getClientLogos = gql`
    query getClientLogos {
        clientLogo {
            data {
                attributes {
                    Items {
                        data {
                            id 
                            attributes {
                                caption
                                alternativeText
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;