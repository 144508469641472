import Reveal from '../Reveal'
import Image from 'next/image'

import styles from '../../styles/JobItem.module.scss'
import Link from 'next/link'


export default function JobItem({ job, index }) {

    const getTime = (time) => {
        switch (time) {
            case 'full_time':
                return 'Vollzeit'
                break
            case 'part_time':
                return 'Teilzeit'
                break
            case 'full_part_time':
                return 'Vollzeit / Teilzeit'
                break
            case 'freelance':
                return 'Freelance'
                break
            default:
                return 'Vollzeit'
                break
        }
    }

    return (
        <Reveal delay={index} key={job?.Slug}>
            <Link prefetch={false} href={`/karriere/${job?.Slug}`}>
                <a>
                    <div className={[styles.wrapper].join(' ')}>
                        <div className={styles.meta}>
                            <h3>{job?.Title}</h3>
                            <p className={styles.meta}>{getTime(job?.Time)} · {job?.Location}</p>
                        </div>
                        <div className={styles.chevron}></div>
                    </div>
                </a>
            </Link>
        </Reveal>
    )
}